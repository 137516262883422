<template>
  <div class="invoice-share-page page">
    <div class="hr"></div>
    <div class="invoice-share-row">
      <div class="invoice-frame">
        <div class="invoice-list"
          v-for="(val, key) in Object.keys(shareData)" :key="key">
          <div class="name">{{ shareData[val].name }}</div>
          <div class="value">{{ shareData[val].value }}</div>
        </div>
      </div>
      <div class="cpoy-btn" @click="copyAction" id="copyBtn"
      :data-clipboard-text="copyTxt">复制全部信息</div>
      <!-- <p class="invoice-desc">保存到我的发票助手</p>
      <p class="invoice-desc">我也要新建发票信息</p> -->
    </div>
    <div class="invoice-footer">
      <div class="qrcode">
        <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220608140401393-qr1.png" alt="" />
        <p class="qrcode-name">小账管家</p>
      </div>
      <p class="intro">下载“小账管家”APP，在“我的-发票助手”即可设置自己的发票</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InvoiceShare',
  data() {
    return {
      copyTxt: '',
    };
  },
  mounted() {
    document.title = '分享发票';
    this.copyTxtData();
  },
  computed: {
    queryData() {
      return this.$route.query;
    },
    shareConfg() {
      return [
        { key: 'invoiceName', name: '名称' },
        { key: 'invoiceNo', name: '税号' },
        { key: 'invoiceAddr', name: '单位地址' },
        { key: 'callPhone', name: '电话号码' },
        { key: 'bankName', name: '开户银行' },
        { key: 'bankNo', name: '银行账户' },
      ];
    },
    shareData() {
      let { queryData, shareConfg } = this;
      let data = {};
      shareConfg.forEach((item) => {
        data[item.key] = {
          name: item.name,
          value: !queryData[item.key] ? '' : decodeURIComponent(queryData[item.key]),
        };
      });
      return data;
    },
  },
  methods: {
    copyAction() {
      const clipboard = window.Clipboard && new window.Clipboard('#copyBtn');
      clipboard?.on('success', (_) => {
        this.$toast({
          content: '复制成功',
        });
        clipboard?.destroy();
      });
      clipboard?.on('error', (_) => {
        console.log('浏览器不支持复制');
        clipboard?.destroy();
      });
    },
    copyTxtData() {
      let txt = '';
      const { shareData } = this;
      Object.keys(shareData).forEach((key) => {
        if (shareData[key].value) {
          txt += `${shareData[key].name}：${shareData[key].value}\n`;
        }
      });
      this.copyTxt = txt;
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-share-page {
  width: 100%;
  max-width: 750px;
  min-height: 100%;
  margin: auto;
  background: #F6F6F6;
  font-family: PingFangSC-Regular, PingFang SC;
  position: relative;
  .hr {
    padding-top: 20px;
  }
}
.invoice-share-row {
  margin: 0 26px;
  padding-bottom: 306px;
  .invoice-frame {
    background-color: #fff;
    border-radius: 16px;
  }
  .invoice-list {
    padding: 32px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    .name {
      color: rgba(51,51,51,0.8);
      min-width: 180px;
    }
    .value {
      color: #333333;
      // width: 70%;
      word-break: break-word;
      // word-wrap:break-word;
      -ms-word-break: break-all;
    }
  }
}
.cpoy-btn {
  width: 100%;
  height: 80px;
  background: #FFDC4D;
  border-radius: 40px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  line-height: 80px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.invoice-desc {
  font-size: 24px;
  font-weight: 400;
  color: rgba(51,51,51,0.6);
  line-height: 34px;
  text-align: center;
  margin-top: 20px;
}
.invoice-footer {
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
  .qrcode {
    img {
      width: 96px;
    }
    .qrcode-name {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      margin-top: 8px;
    }
  }
  .intro {
    font-size: 20px;
    font-weight: 400;
    color: rgba(51,51,51,0.6);
    line-height: 28px;
    margin-top: 20px;
  }
}
</style>
